import styled from "styled-components";

const AppointmentWrapper = styled.div`
  div#appointmentPage {
    padding-top: 30px;
  }
  
  @media only screen and (max-width: 768px) {
    input[type="date"]:not(.has-value):before{
      color: #858585;
      content: attr(placeholder);
      position: absolute;
    }
  }

  button.btn.btn-info {
    padding: 15px 50px;
    border-radius: 5px;
    background: linear-gradient(180deg, #ffa238 0%, #8a4a00 100%);
    color: #ffffff;
    margin-top: 15px;
    border: 0;
  }
  div#appointmentPage .row {
    max-width: 800px;
    margin: 0 auto;
  }
  form#needs-validation {
    border: 1px solid #dedede;
    padding: 30px;
    border-radius: 10px;
  }
  h3.text-center.text-inverse {
    margin-top: 35px;
    margin-bottom: 25px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  label {
    display: inline-block;
    margin-bottom: 7px;
  }

  @media only screen and (max-device-width: 1024px) {
  }

  @media only screen and (max-device-width: 768px) {
  }
`;

export default AppointmentWrapper;
